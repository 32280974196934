import IImage from '../models/Image';
import { IPictureObj } from '../models/Picture';
import IBreakpoint from '../models/Breakpoints';
import { BaseQueryOptions } from 'react-query';
import { WretchOptions } from 'wretch';

/**
 * The supported languages
 */
export const LANGUAGES: { it: string; en: string; es: string; de: string; fr: string; nl: string } = {
  it: 'it',
  en: 'en',
  es: 'es',
  de: 'de',
  fr: 'fr',
  nl: 'nl',
};

/**
 * The available languages of the project
 */
export const AVAILABLE_LANGUAGES: string[] = [...Object.keys(LANGUAGES)];

/**
 * The logo link and description
 */
export const LOGO: IImage = {
  alt: 'PhotoSì Logo',
  src: {
    it: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo.svg',
    en: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo_en.svg',
    es: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo_es.svg',
    de: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo_de.svg',
    nl: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo_nl.svg',
    fr: 'https://www.photosi.com/hubfs/PC_SITE_2019/images/loghi_photosi_site/logo_fr.svg',
  },
};

/**
 * The footer logo link and description
 */
export const FOOTER_LOGO: IImage = {
  alt: LOGO.alt,
  singleSrc: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_logo.svg',
};

/**
 * The icons of the footer
 */
export const FOOTER_ICONS: IPictureObj = {
  numberOne: {
    alt: 'N°1 Icon',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_1_xxl.svg',
      },
    ],
    src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_1_lg.svg',
  },
  madeInItaly: {
    alt: 'Italy Flag',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_2_xxl.svg',
      },
    ],
    src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_2_lg.svg',
  },
  moneyBack: {
    alt: 'Smile Icon',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_3_xxl.svg',
      },
    ],
    src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_100_3_lg.svg',
  },
  securePayment: {
    alt: 'Payments Logo',
    sources: [
      {
        dim: '(min-width: 1920px)',
        src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_pay_xxl.svg',
      },
      {
        dim: '(min-width: 1440px)',
        src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_pay_lg.svg',
      },
    ],
    src: 'https://www.photosi.com/hubfs/3005015/PC_SITE_2019/images/footer/footer_pay.svg',
  },
};

/**
 * The name of the cookie for the language
 */
export const LANGUAGE_COOKIE: string = 'phs_choosen_language';

/**
 * The courier image
 */
export const COURIER_IMG: IImage = {
  alt: 'Courier Image',
  singleSrc: 'https://www.photosi.com/hubfs/Tracking/Corriere.svg',
};

/**
 * The breakpoint map
 */
export const BREAKPOINT_MAP: IBreakpoint = {
  xxs: 0,
  xs: 360,
  sm: 540,
  md: 768,
  lg: 1366,
  xl: 1440,
  xxl: 1920,
};

/**
 * The config for react query
 */
export const QUERY_CONFIG: BaseQueryOptions = {
  enabled: false,
  refetchOnWindowFocus: false,
  retry: 1,
};

/**
 * The params for wretch
 */
export const WRETCH_PARAMS: WretchOptions = {
  headers: {
    'x-api-key': `${import.meta.env.VITE_APP_X_API_KEY}`,
  },
};

/**
 * The errors type
 */
export const ERRORS_TYPE: { api: string; generic: string; tracking: string; [key: string]: string } = {
  api: 'api',
  generic: 'generic',
  tracking: 'tracking',
};

/**
 * The button texts type
 */
export const BUTTON_TEXTS_TYPE: { reservedArea: string; retry: string; site: string; [key: string]: string } = {
  reservedArea: 'reservedArea',
  retry: 'retry',
  site: 'site',
};

/**
 * The sentry dns
 */
export const SENTRY_DSN = `${import.meta.env.VITE_APP_SENTRY_DSN}`;

/**
 * GoogleTagManager ID
 */
export const GTM_ID = import.meta.env.VITE_APP_GTM_ID || '';
export const DATALAYER_NAME = 'dataLayer';
export const DATALAYER_EVENT_CATEGORY = 'trackingConsumer';
export const GTM_GAEVENT = 'GAevent';
export const RESERVED_AREA_URL = import.meta.env.VITE_APP_RESERVED_AREA_URL;

export const PHOTOSI_URL: string = `${import.meta.env.VITE_APP_PHOTOSI_URL}`;

export const DIDOMI_CONSENT_ID = import.meta.env.VITE_APP_DIDOMI_CONSENT_ID;
export const DIDOMI_API_KEY = import.meta.env.VITE_APP_DIDOMI_API_KEY;
