import React from 'react';
import { useTranslation } from 'react-i18next';
import { BREAKPOINT_MAP, ERRORS_TYPE } from '../utils/consts';
import { Typography } from '@photosi/web-design-system';
import useWindowWidth from '../hooks/useWindowWidth';

export type ErrorProps = {
  type: (typeof ERRORS_TYPE)[keyof typeof ERRORS_TYPE];
};

const Error: React.FC<ErrorProps> = ({ type }) => {
  const { t } = useTranslation('errors');

  const windowWidth = useWindowWidth();
  const getCorrectVariant = (windowWidth: number) => {
    if (windowWidth >= BREAKPOINT_MAP.xxl) {
      return 'heading2XL';
    }
    if (windowWidth >= BREAKPOINT_MAP.lg) {
      return 'subhadM';
    }
    return 'subheadS';
  };

  return (
    <Typography
      data-testid="error-message"
      className="phs-tracking-error"
      variant={getCorrectVariant(windowWidth as number)}
    >
      {t(`${ERRORS_TYPE[type]}`)}
    </Typography>
  );
};

export default Error;
