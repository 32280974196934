import React from 'react';
import Image from '../../components/Image';

import { COURIER_IMG } from '../../utils/consts';

const TrackingImage = () => {
  return <Image alt={COURIER_IMG.alt} src={COURIER_IMG.singleSrc!} width="100%" height="100%" />;
};

export default TrackingImage;
