import React from 'react';
import { IOrder } from '../../models/Order';
import OrderItemCheck from './OrderItemCheck';
import OrderItemTexts from './OrderItemTexts';
import useOrderStatusChecks from '../../hooks/useOrderStatusChecks';

export type TrackingOrderItemProps = {
  itemOrderState: string;
  orderData: IOrder;
};

const TrackingOrderItem: React.FC<TrackingOrderItemProps> = ({ itemOrderState, orderData }) => {
  const {
    deliveryDate,
    expectedDeliveryDate,
    expectedShippingDate,
    externalTrackingUrl,
    isDelayed,
    isShippingAddressEditable,
    orderDate,
    status: orderStatus,
    orderShippingType,
    userAddress,
    isShippingDelayed,
  } = orderData;

  const { checksWarnings, checksStatus } = useOrderStatusChecks({
    expectedDeliveryDate,
    expectedShippingDate,
    isDelayed,
    orderStatus,
    orderShippingType,
    deliveryDate,
  });
  const isChecked = checksStatus[itemOrderState];
  const hasWarning = checksWarnings[itemOrderState];

  return (
    <div className={`phs-order-item ${hasWarning ? 'long_message' : ''}`} data-testid="order-item-container">
      <OrderItemCheck hasWarning={hasWarning} isChecked={isChecked} />
      <OrderItemTexts
        isShippingDelayed={isShippingDelayed}
        currentAppOrderStatus={itemOrderState}
        deliveryDate={deliveryDate}
        expectedDeliveryDate={expectedDeliveryDate}
        expectedShippingDate={expectedShippingDate}
        externalTrackingUrl={externalTrackingUrl}
        isDelayed={hasWarning}
        orderDate={orderDate}
        orderStatus={orderStatus}
        shippingType={orderShippingType}
        userAddress={userAddress}
        isShippingAddressEditable={isShippingAddressEditable}
        orderId={orderData.orderId}
      />
    </div>
  );
};

export default TrackingOrderItem;
