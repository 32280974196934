import React from 'react';

type ImageProps = {
  alt: string;
  src: string;
  width?: string;
  height?: string;
};

const Image: React.FC<ImageProps> = ({ alt, src, width, height }) => (
  <img alt={alt} src={src} style={{ height, width }} />
);

export default Image;
