import { MouseEventHandler, useMemo } from 'react';
import Error from '../../components/Error';
import { Button } from '@photosi/web-design-system';
import { BUTTON_TEXTS_TYPE, ERRORS_TYPE } from '../../utils/consts';
import { useTranslation } from 'react-i18next';

export type TrackingErrorProps = {
  isError: boolean;
  error?: { name: string; message: string; status: string };
  buttonFn: MouseEventHandler<HTMLButtonElement>;
};

const TrackingError: React.FC<TrackingErrorProps> = ({ isError, error, buttonFn }) => {
  const { t } = useTranslation('buttons');
  const selectedErrorMessage = useMemo(() => {
    const errorMessagesMap: Record<string, string> = {
      '400': ERRORS_TYPE.tracking,
      '404': ERRORS_TYPE.tracking,
      '500': ERRORS_TYPE.api,
      default: ERRORS_TYPE.generic,
    };
    const pickedMessage = errorMessagesMap[error?.status as string];
    if (!pickedMessage) {
      return errorMessagesMap.default;
    }

    return pickedMessage;
  }, [error?.status]);

  if (!isError) {
    return null;
  }

  return (
    <div>
      <Error type={selectedErrorMessage} />
      <Button onClick={buttonFn} size="md">
        {t(BUTTON_TEXTS_TYPE.retry)}
      </Button>
    </div>
  );
};

export default TrackingError;
