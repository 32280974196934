import { QueryCache, QueryClient, QueryClientProvider, QueryResult } from 'react-query';
import * as Sentry from '@sentry/react';
import Container from './components/Container';
import Tracking from './features/Tracking';
import { TrackingProvider } from './components/TrackingContext';
import { isAppView } from './utils/funcs';
import { IOrder } from './models/Order';
import { FooterPayments, Header } from '@photosi/web-design-system';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      Sentry.captureException(error);
    },
    onSuccess: (data: QueryResult<IOrder>) => {
      if (data?.shippingRowGroupCount > 1) {
        window.location.assign(`${import.meta.env.VITE_APP_RESERVED_AREA_URL}/orders/${data?.orderId}`);
      }
    },
  }),
});

function TrackingApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <TrackingProvider>
        <Container>
          {!isAppView() ? <Header /> : null}
          <Tracking />
          {!isAppView() ? <FooterPayments /> : null}
        </Container>
      </TrackingProvider>
    </QueryClientProvider>
  );
}

export default TrackingApp;
