import React, { useEffect } from 'react';
import useWindowWidth from '../hooks/useWindowWidth';
import usePHSTracking from '../hooks/usePHSTracking';
import { BREAKPOINT_MAP } from '../utils/consts';
import TrackingContainer from './components/TrackingContainer';
import TrackingImage from './components/TrackingImage';
import Loader from '../components/Loader';

const Tracking: React.FC = () => {
  const windowWidth = useWindowWidth();

  const {
    trackingData: { data: orderData, error, isError, isLoading, isSuccess, refetch },
    setTracking,
  } = usePHSTracking();

  useEffect(() => {
    setTracking();
  }, [setTracking]);

  return (
    <main className={`phs-container ${windowWidth! < BREAKPOINT_MAP.md ? 'pds-flex pds-justify-center' : ''}`}>
      <div className={windowWidth! >= BREAKPOINT_MAP.md ? 'pds-grid pds-grid-cols-2 pds-px-6' : 'pds-px-14'}>
        {windowWidth! >= BREAKPOINT_MAP.md && (
          <div className="pds-justify-self-center pds-self-center">
            <TrackingImage />
          </div>
        )}
        <div className="pds-flex pds-flex-col pds-gap-4">
          {isLoading && (
            <div className="pds-flex pds-items-center pds-grow">
              <Loader />
            </div>
          )}
          <TrackingContainer
            isSuccess={isSuccess}
            orderData={orderData}
            isError={isError}
            error={error}
            refetch={refetch}
          />
        </div>
      </div>
    </main>
  );
};

export default Tracking;
