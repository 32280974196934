import jsCookies from 'js-cookie';
import queryString from 'query-string';
import moment from 'moment';
import { format, isFuture, isToday, isPast } from 'date-fns';
import { it, es, enUS } from 'date-fns/locale';

import { AVAILABLE_LANGUAGES, LANGUAGE_COOKIE, LANGUAGES } from './consts';

/**
 * app_view is passed as param in mobile request
 */
export function isAppView(): boolean {
  const appView: string | null = new URLSearchParams(window.location.search).get('app_view');
  return appView && parseInt(appView) === 1 ? true : false;
}

/**
 * The available locales
 */
const locales: { [key: string]: Locale } = { it, es, en: enUS };

/**
 * Function to get the language of the browser
 */
export function getBrowserLanguage(): string {
  const hasDash = navigator.language.includes('-');

  return hasDash ? navigator.language.split('-')[0] : navigator.language;
}

/**
 * Function to get the current language based on cookie or browser
 */
export function getLanguage(): string {
  const languageCookie = jsCookies.get(`${LANGUAGE_COOKIE}`);
  const browserLanguage = getBrowserLanguage();

  if (typeof languageCookie !== 'undefined' && AVAILABLE_LANGUAGES.includes(languageCookie)) {
    return languageCookie;
  }

  if (AVAILABLE_LANGUAGES.includes(browserLanguage)) return browserLanguage;

  return LANGUAGES.it;
}

/**
 * Function to get the current year
 */
export function getCurrentYear(): number {
  return new Date().getUTCFullYear();
}

/**
 * Get the parsed Tracking Id
 * @param trackingNumber - The number of the tracking
 */
export function getParsedTrackingId(trackingNumber: string): string {
  if (trackingNumber.includes('?')) {
    const qs = queryString.parse(trackingNumber);
    const { trackingId } = qs;

    return typeof trackingId === 'string' ? trackingId : '';
  }

  return trackingNumber;
}

/**
 * Function to get the date formatted by the locale
 * @param date - The date to format
 * @param language - The current language
 */
function getFormattedDate(date: string, language: string): string {
  return format(new Date(moment.utc(date).format()), 'd MMMM', { locale: locales[language] });
}

/**
 * The function to get the dates formatted
 * @param deliveryDate - The delivery date
 * @param expectedDeliveryDate - The expected delivery date
 * @param expectedShippingDate - The expected shipping date
 * @param orderDate - The date when the order was placed
 */
export function getFormattedDates({
  deliveryDate,
  expectedDeliveryDate,
  expectedShippingDate,
  orderDate,
}: {
  deliveryDate: string | null;
  expectedDeliveryDate: string;
  expectedShippingDate: string;
  orderDate: string;
}): {
  formattedDeliveryDate: string;
  formattedExpectedDeliveryDate: string;
  formattedExpectedShippingDate: string;
  formattedOrderDate: string;
} {
  const language = getLanguage();
  const formattedDeliveryDate = deliveryDate !== null ? getFormattedDate(deliveryDate, language) : '';
  const formattedExpectedDeliveryDate =
    expectedDeliveryDate !== null ? getFormattedDate(expectedDeliveryDate, language) : '';
  const formattedExpectedShippingDate =
    expectedShippingDate !== null ? getFormattedDate(expectedShippingDate, language) : '';
  const formattedOrderDate = orderDate !== null ? getFormattedDate(orderDate, language) : '';

  return { formattedDeliveryDate, formattedExpectedDeliveryDate, formattedExpectedShippingDate, formattedOrderDate };
}

/**
 * Function to get if the date is today or in the future
 * @param date - The date to check
 */
export function getIsSameOrFutureDate(date: string, roundedHours: boolean = true): boolean {
  if (date === null) {
    return false;
  }

  const dateToCheck = new Date(date);
  if (roundedHours) {
    dateToCheck.setHours(0);
    dateToCheck.setMinutes(0);
    dateToCheck.setSeconds(0);
    dateToCheck.setMilliseconds(0);
  }
  return isToday(dateToCheck) || isFuture(dateToCheck);
}

export function isFutureDate(date: string, roundedHours: boolean = true): boolean {
  if (date === null) {
    return false;
  }

  const dateToCheck = new Date(date);
  if (roundedHours) {
    dateToCheck.setHours(0);
    dateToCheck.setMinutes(0);
    dateToCheck.setSeconds(0);
    dateToCheck.setMilliseconds(0);
  }
  return isFuture(dateToCheck);
}

export function isPastDate(date: string, roundedHours: boolean = true): boolean {
  if (date === null) {
    return false;
  }

  const dateToCheck = new Date(date);
  if (roundedHours) {
    dateToCheck.setHours(23);
    dateToCheck.setMinutes(59);
    dateToCheck.setSeconds(59);
    dateToCheck.setMilliseconds(59);
  }
  return isPast(dateToCheck);
}

export function isDev() {
  if (
    window.location.origin &&
    (window.location.origin.indexOf('localhost') !== -1 || window.location.origin.indexOf('photoforse') !== -1)
  ) {
    return true;
  }

  return false;
}
