import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import PHSTracking from '../models/PHSTracking';
import { QUERY_CONFIG } from '../utils/consts';
import useGetOrder from './useGetOrder';

/**
 * Custom hook for handle the tracking status
 */
function usePHSTracking(): PHSTracking {
  const [orderId, setOrderId] = useState<string>('');
  const [trackingId, setTrackingId] = useState<string>('');
  const [shippingRowGroupGuid, setShippingRowGroupGuid] = useState<string>('');
  const [getOrder] = useGetOrder({ orderId, trackingId, shippingRowGroupGuid });
  const { data, error, isError, isFetching, isLoading, isSuccess, refetch } = useQuery(
    ['tracking', trackingId, orderId, shippingRowGroupGuid],
    async () => getOrder(),
    QUERY_CONFIG,
  );

  function setTracking(qs = window.location.search): void {
    const { trackingId, orderId, shippingRowGroupGuid } = queryString.parse(qs);
    if (trackingId && typeof trackingId === 'string') {
      setTrackingId(trackingId);
    } else if (orderId && typeof orderId === 'string') {
      setOrderId(orderId);
    } else if (shippingRowGroupGuid && typeof shippingRowGroupGuid === 'string') {
      setShippingRowGroupGuid(shippingRowGroupGuid);
    } else {
      setTrackingId('');
    }
  }

  // eslint-disable-next-line
  const memoSetTracking = useMemo(() => setTracking, []);

  useEffect(() => {
    if (trackingId.length > 0 || orderId.length > 0 || shippingRowGroupGuid.length > 0) {
      refetch();
    }
  }, [shippingRowGroupGuid, trackingId, orderId, refetch]);

  return {
    trackingId,
    orderId,
    shippingRowGroupGuid,
    trackingData: {
      data,
      error,
      isError,
      isFetching,
      isLoading,
      isSuccess,
      refetch,
    },
    setTracking: memoSetTracking,
  };
}

export default usePHSTracking;
