import { IUserOrderStatusChecksParams } from './../models/OrderStatusesHook';
import { ORDER_STATUS } from './orderStatus';
import { isFutureDate, isPastDate } from './funcs';

/**
 * custom error class to identify the type of error in sentry
 */

export const checkBusinessLogicsFromBackend = ({
  expectedDeliveryDate,
  expectedShippingDate,
  isDelayed,
  orderStatus,
}: IUserOrderStatusChecksParams) => {
  let isError = false;
  let errorMessage = '';

  //const isDeliveryDateInThePast = getIsSameOrFutureDate(expectedDeliveryDate)
  //const isShippedDateInThePast = isPastDate(expectedShippingDate) //isFutureDate (is the reverse function)

  if (orderStatus !== ORDER_STATUS.delivered) {
    /**
     * e' spedito, ma la data di spedizione è nel futuro
     */
    if (orderStatus === ORDER_STATUS.shipped && isFutureDate(expectedShippingDate)) {
      errorMessage += 'is shipped, but the shipping date is in the future\n';
      isError = true;
    }

    /**
     * l'ordine non è spedito, ma la data di spedizione è nel passato
     */
    if (orderStatus !== ORDER_STATUS.shipped && isDelayed === false && isPastDate(expectedShippingDate)) {
      errorMessage += 'order status is not shipped, and delayed status is False, but the shipping date is past\n';
      isError = true;
    }
  }

  const messageName = errorMessage;

  if (isError) {
    errorMessage += JSON.stringify({
      expectedDeliveryDate: expectedDeliveryDate,
      expectedShippingDate: expectedShippingDate,
      isDelayed: isDelayed,
      orderStatus: orderStatus,
    });
  }

  return { isError, errorMessage, messageName };
};

export default checkBusinessLogicsFromBackend;
