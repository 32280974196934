import { IOrder, IShippingRowGroup, OrderType } from '../models/Order';
import wretch from 'wretch';
import { WRETCH_PARAMS } from '../utils/consts';
import * as Sentry from '@sentry/browser';

type TGetterParams = {
  trackingId: string;
  orderId: string;
  shippingRowGroupGuid: string;
};

/**
 * Hook to get order's data by type of params
 */
function useGetOrder({ trackingId, orderId, shippingRowGroupGuid }: TGetterParams) {
  /**
   * Function to get the order via the API by orderId
   * @param id - The order Id
   */
  async function getOrderByOrderID(id: string) {
    return getData(`${import.meta.env.VITE_APP_TRACKING_API}/orders/${id}`) as unknown as IOrder;
  }

  /**
   * Function to get the order via the API by trackingId
   * @param id - The tracking Id
   */
  async function getOrderByTrackingID(id: string) {
    Sentry.captureException('getOrder used trackingId to request data');
    return getData(`${import.meta.env.VITE_APP_TRACKING_API}/trackings/${id}`) as unknown as IOrder;
  }

  /**
   * Function to get the order via the API by shippingrowGroupGuid
   * @param id - The tracking Id
   */
  async function getOrderByShippingRowGroupID(id: string) {
    return getData(
      `${import.meta.env.VITE_APP_TRACKING_API}/shipping-row-groups/${id}`,
    ) as unknown as IShippingRowGroup;
  }

  async function getData(url: string): Promise<OrderType> {
    return await wretch(url, { ...WRETCH_PARAMS })
      .get()
      .badRequest(err => {
        throw Object.assign(new Error(), { name: '400', message: err.message, status: '400' });
      })
      .notFound(err => {
        throw Object.assign(new Error(), { name: '404', message: err.message, status: '404' });
      })
      .timeout(err => {
        throw Object.assign(new Error(), { name: '408', message: err.message, status: '408' });
      })
      .internalError(err => {
        throw Object.assign(new Error(), {
          name: JSON.parse(err.message).title,
          message: err.message,
          status: err.status,
        });
      })
      .fetchError(err => {
        throw Object.assign(new Error(), { name: err.name, message: err.message, status: err.status });
      })
      .json();
  }

  async function getOrder(): Promise<OrderType | undefined> {
    if (shippingRowGroupGuid) return await getOrderByShippingRowGroupID(shippingRowGroupGuid);
    else if (trackingId) return await getOrderByTrackingID(trackingId);
    else if (orderId) return await getOrderByOrderID(orderId);
  }

  return [getOrder];
}

export default useGetOrder;
