import React from 'react';
import { createRoot } from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import it from './locale/it.json';
import en from './locale/en.json';
import es from './locale/es.json';
import de from './locale/de.json';
import fr from './locale/fr.json';
import nl from './locale/nl.json';

import './assets/scss/index.scss';
import TrackingApp from './TrackingApp';

import { SENTRY_DSN, GTM_ID, DATALAYER_NAME } from './utils/consts';
import { getLanguage, isAppView } from './utils/funcs';
import { BackendBusinessLogicCheckError, InfoMessageError } from './utils/errorLevelInfo';
import { Severity } from '@sentry/browser';
import Error from './components/Error';
import CookieConsent from './CookieConsent';

const language = getLanguage();

i18n.use(initReactI18next).init({
  resources: {
    it,
    en,
    es,
    de,
    nl,
    fr,
  },
  lng: language,
  fallbackLng: 'en',
});

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment: process.env.NODE_ENV,
  beforeSend: function (event, hint: any) {
    const exception = hint.originalException;
    if (exception instanceof BackendBusinessLogicCheckError) {
      event.fingerprint = ['backend_data_logic_is_not_valid'];
      event.level = Severity.Warning;
    }

    if (exception instanceof InfoMessageError) {
      event.fingerprint = ['info_message', event.message || 'info_message'];
      event.level = Severity.Info;
    }
    return event;
  },
});

/**
 * Start Google Tag Manager
 * if app_view is passed in params Tag manager hasn't to be showed
 */
if (!isAppView()) {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: DATALAYER_NAME,
  });
}

const container = document.getElementById('trackingApp');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Error type="tracking" />}>
      {!isAppView ? <CookieConsent /> : null}
      <TrackingApp />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
