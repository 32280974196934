import React from 'react';
import { Typography } from '@photosi/web-design-system';
import { Icon } from '@photosi/web-design-system';

interface TrackingLinkProps {
  subtitle: string;
  tracking: string;
  externalTrackingUrl: string;
  showLink: boolean;
}

const TrackingLink: React.FC<TrackingLinkProps> = ({ subtitle, tracking, externalTrackingUrl, showLink }) => (
  <Typography variant="bodyS">
    {subtitle}
    {showLink && (
      <a
        className="trackingLink"
        href={externalTrackingUrl}
        data-external-tracking-link={externalTrackingUrl}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <Typography variant="headingM" className="pds-flex pds-items-center">
          {tracking}
          <Icon name="arrowRight" />
        </Typography>
      </a>
    )}
  </Typography>
);

export default TrackingLink;
