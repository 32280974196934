import React from 'react';
import { isAppView } from '../utils/funcs';

type ContainerProps = {
  children: React.ReactNode;
  classes?: string;
};

const Container: React.FC<ContainerProps> = ({ children, classes = isAppView() ? 'wrapper_appview' : 'wrapper' }) => (
  <div className={classes}>{children}</div>
);

export default Container;
