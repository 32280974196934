import React from 'react';
import { IOrder } from '../../models/Order';
import useOrderTexts from '../../hooks/useOrderTexts';
import SecondParagraph from '../../components/SecondParagraph';
import { Typography } from '@photosi/web-design-system';

/**
 * The shape of the props
 */

export type OrderItemTextsProps = {
  currentAppOrderStatus: string;
  deliveryDate: Pick<IOrder, 'deliveryDate'>['deliveryDate'];
  expectedDeliveryDate: Pick<IOrder, 'expectedDeliveryDate'>['expectedDeliveryDate'];
  expectedShippingDate: Pick<IOrder, 'expectedShippingDate'>['expectedShippingDate'];
  externalTrackingUrl: Pick<IOrder, 'externalTrackingUrl'>['externalTrackingUrl'];
  isDelayed: Pick<IOrder, 'isDelayed'>['isDelayed'];
  isShippingDelayed: Pick<IOrder, 'isShippingDelayed'>['isShippingDelayed'];
  orderId: Pick<IOrder, 'orderId'>['orderId'];
  isShippingAddressEditable: Pick<IOrder, 'isShippingAddressEditable'>['isShippingAddressEditable'];
  orderDate: Pick<IOrder, 'orderDate'>['orderDate'];
  orderStatus: Pick<IOrder, 'status'>['status'];
  shippingType: Pick<IOrder, 'orderShippingType'>['orderShippingType'];
  userAddress: Pick<IOrder, 'userAddress'>['userAddress'];
};

const OrderItemTexts: React.FC<OrderItemTextsProps> = ({
  currentAppOrderStatus,
  deliveryDate,
  expectedDeliveryDate,
  expectedShippingDate,
  externalTrackingUrl,
  isDelayed,
  isShippingAddressEditable,
  orderId,
  orderDate,
  orderStatus,
  shippingType,
  userAddress,
  isShippingDelayed,
}) => {
  const { delayedParagraph, firstParagraph } = useOrderTexts({
    currentAppOrderStatus,
    deliveryDate,
    expectedDeliveryDate,
    expectedShippingDate,
    externalTrackingUrl,
    orderDate,
    orderStatus,
    shippingType,
    userAddress,
    isShippingAddressEditable,
    orderId,
    isShippingDelayed,
  });

  const orderData = {
    currentAppOrderStatus,
    deliveryDate,
    expectedDeliveryDate,
    expectedShippingDate,
    externalTrackingUrl,
    orderDate,
    orderStatus,
    shippingType,
    isShippingAddressEditable,
    userAddress,
    orderId,
    isShippingDelayed,
  };

  return (
    <div className="order-item__paragraphs">
      <Typography variant="headingL" data-testid="first-paragraph">
        {firstParagraph}
      </Typography>
      <SecondParagraph {...orderData} />
      <br />
      {isDelayed && (
        <Typography className="delayed-paragraph" variant="headingM">
          {delayedParagraph}
        </Typography>
      )}
    </div>
  );
};

export default OrderItemTexts;
