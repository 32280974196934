import React from 'react';
import { Icon } from '@photosi/web-design-system';

type OrderItemCheckProps = {
  hasWarning: boolean;
  isChecked: boolean;
};

const OrderItemCheck: React.FC<OrderItemCheckProps> = ({ hasWarning, isChecked }) => {
  const checkedClass = isChecked ? 'is-checked' : '';
  const warningClass = hasWarning ? 'has-warning' : '';

  return (
    <div className="pds-relative">
      <span className={`check-container ${checkedClass} ${warningClass}`}>
        <Icon name="check" className="pds-text-white" />
      </span>
      <div className={`phs-progress-line ${isChecked ? 'greenlight' : ''}`} />
    </div>
  );
};

export default OrderItemCheck;
