import React from 'react';
import { DIDOMI_CONSENT_ID, DIDOMI_API_KEY } from './utils/consts';
import { DidomiSDK } from '@didomi/react';

const CookieConsent = () => {
  if (!DIDOMI_CONSENT_ID) {
    return null;
  }
  return (
    <DidomiSDK
      apiKey={DIDOMI_API_KEY}
      noticeId={DIDOMI_CONSENT_ID}
      iabVersion={2}
      embedTCFStub={true}
      gdprAppliesGlobally={true}
    />
  );
};

export default CookieConsent;
