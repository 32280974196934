import React from 'react';
import { Typography } from '@photosi/web-design-system';
import './alert.scss';

interface AlertProps {
  title: string;
  description: string;
}

const Alert: React.FC<AlertProps> = ({ title, description }) => (
  <div className="phs-alert">
    <Typography variant="headingM"> {title}</Typography>
    <Typography variant="bodyS">{description}</Typography>
  </div>
);
export default Alert;
