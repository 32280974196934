import { PropsWithChildren, createContext } from 'react';
import { IOrderStatus } from '../models/Order';
import { ORDER_STATUS } from '../utils/orderStatus';

type TrackingContextProps = {
  orderStatus: IOrderStatus;
};

const initialTrackingContext = {
  orderStatus: ORDER_STATUS,
};

export const TrackingContext = createContext<TrackingContextProps>(initialTrackingContext);

export const TrackingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <TrackingContext.Provider value={initialTrackingContext}>{children}</TrackingContext.Provider>;
};
