import React from 'react';
import TrackingInfo from './TrackingInfo';
import TrackingError from './TrackingError';
import { IOrder } from '../../models/Order';
import { ErrorType, IsErrorType, IsSuccessType, RefetchType } from '../../models/PHSTracking';

type TrackingContainerProps = {
  isSuccess: IsSuccessType;
  orderData: IOrder;
  isError: IsErrorType;
  error: ErrorType;
  refetch: RefetchType;
};

const TrackingContainer: React.FC<TrackingContainerProps> = ({ isSuccess, orderData, isError, error, refetch }) => {
  return (
    <>
      <TrackingInfo isSuccess={isSuccess} orderData={orderData} />
      <TrackingError isError={isError} error={error} buttonFn={refetch} />
    </>
  );
};

export default TrackingContainer;
