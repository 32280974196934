import React from 'react';
import TrackingNumber from './TrackingNumber';
import TrackingList from './TrackingList';
import { IsSuccessType } from '../../models/PHSTracking';
import { IOrder } from '../../models/Order';

type TrackingInfoProps = {
  isSuccess: IsSuccessType;
  orderData: IOrder;
};

const TrackingInfo: React.FC<TrackingInfoProps> = ({ isSuccess, orderData }) => {
  return (
    typeof orderData !== 'undefined' &&
    isSuccess && (
      <>
        <TrackingNumber trackingId={orderData.orderNumber} />
        <TrackingList orderData={orderData} />
      </>
    )
  );
};

export default TrackingInfo;
