import { IOrderShippingType, IOrderStatus } from '../models/Order';

/**
 * The status of an order
 */
export const ORDER_STATUS: IOrderStatus = {
  created: 'Created',
  inProgress: 'InProgress',
  shipped: 'Shipped',
  delivered: 'Delivered',
  readyToPickup: 'ReadyToPickup',
};

/**
 * The shipping types
 */
export const ORDER_SHIPPING_TYPES: IOrderShippingType = {
  mailOrder: 'MailOrder',
  pickUp: 'PickUpStore',
  express: 'Express',
};
