import { Typography } from '@photosi/web-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import { BREAKPOINT_MAP } from '../../utils/consts';

type TrackingNumberProps = {
  trackingId: string | number;
};

const TrackingNumber: React.FC<TrackingNumberProps> = ({ trackingId }) => {
  const { t } = useTranslation('tracking');
  const windowWidth = useWindowWidth();
  const getCorrectVariant = (windowWidth: number) => {
    if (windowWidth >= BREAKPOINT_MAP.xxl) {
      return 'heading4XL';
    }
    if (windowWidth >= BREAKPOINT_MAP.lg) {
      return 'heading2XL';
    }
    if (windowWidth >= BREAKPOINT_MAP.md) {
      return 'subhadM';
    }
    return 'subheadS';
  };
  return (
    <div
      className={`phs-tracking-number pds-flex pds-flex-col ${windowWidth! < BREAKPOINT_MAP.md ? 'pds-items-center' : ''}`}
    >
      {/* TODO: one the design system will be updated, change the variant name subhadM with the correct name (means: without typo) */}
      <Typography variant={getCorrectVariant(windowWidth as number)} className="pds-font-medium">
        {t('orderNumber')}
      </Typography>
      <Typography
        variant={windowWidth! < BREAKPOINT_MAP.md ? 'headingL' : getCorrectVariant(windowWidth as number)}
        className="txt-orange"
        data-testid="tracking-id-text"
      >
        {trackingId}
      </Typography>
    </div>
  );
};

export default TrackingNumber;
