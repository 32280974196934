import React, { useEffect } from 'react';

import { IOrder } from '../../models/Order';

import TrackingOrderItem from './TrackingOrderItem';

import { checkBusinessLogicsFromBackend } from './../../utils/checkBusinessLogicsFromBackend';
import { BackendBusinessLogicCheckError } from './../../utils/errorLevelInfo';
import * as Sentry from '@sentry/browser';
import { useTrackingContext } from '../../hooks/useTrackingContext';
import { ORDER_SHIPPING_TYPES, ORDER_STATUS } from '../../utils/orderStatus';

type TrackingListProps = {
  orderData: IOrder;
};

const TrackingList: React.FC<TrackingListProps> = ({ orderData }) => {
  const { orderStatus } = useTrackingContext();
  const status = Object.values(orderStatus);

  /**
   * check consistence of data from backend, in case of issues, send message at sentry
   */
  useEffect(() => {
    const { expectedDeliveryDate, expectedShippingDate, isDelayed } = orderData;
    const { isError, errorMessage, messageName } = checkBusinessLogicsFromBackend({
      expectedDeliveryDate,
      expectedShippingDate,
      isDelayed,
      orderStatus: orderData.status,
    });
    if (isError) {
      const businessLogicCheckException = new BackendBusinessLogicCheckError();
      businessLogicCheckException.message = errorMessage;
      businessLogicCheckException.name = messageName;
      Sentry.captureException(businessLogicCheckException);
    }
  }, [orderData]);

  // check if the order will be delivered to a store
  const isMailOrderOrExpress =
    orderData.orderShippingType === ORDER_SHIPPING_TYPES.mailOrder ||
    orderData.orderShippingType === ORDER_SHIPPING_TYPES.express;
  /**
   * TODO: Refactor this code
   * The tracking consumer currently shows 4 steps. Prior to 9/10/2024, these steps matched the status.
   * After this date, a new status was added, so the previous status.map logic no longer works.
   * NextStatus is now a copy of the status, with the "delivered" or "ready-to-pickup" status based on the order's shipping type.
   */
  const nextStatus = status.filter(state =>
    isMailOrderOrExpress ? state !== ORDER_STATUS.readyToPickup : state !== ORDER_STATUS.delivered,
  );

  return (
    <div className="phs-tracking-list pds-grow pds-flex pds-flex-col pds-justify-around">
      {nextStatus.map(state => (
        <TrackingOrderItem key={state} itemOrderState={state} orderData={orderData} />
      ))}
    </div>
  );
};

export default TrackingList;
