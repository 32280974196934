import { useCallback, useEffect, useState } from 'react';

/**
 * Function to get the current width
 * @param isClient - If is in client or not
 */
function getWidth(isClient: boolean): number | undefined {
  return isClient ? window.innerWidth : undefined;
}

/**
 * Custom hook for get the width of the browser
 */
function useWindowWidth(): number | undefined {
  const isClient = typeof window === 'object';
  const memoGetWidth = useCallback(getWidth, []);
  const [windowWidth, setWindowWidth] = useState(memoGetWidth(isClient));

  useEffect(() => {
    if (!isClient) return;

    function handleResize(): void {
      setWindowWidth(memoGetWidth(isClient));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, memoGetWidth]);

  return windowWidth;
}

export default useWindowWidth;
